
import React, { useEffect, useState } from 'react'
import classes from './DashboardTitle.module.css'
import axios from "axios"
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import { getCookie } from "../../Services/Cookie/Cookie"
import { useNavigate, Link, useLocation } from 'react-router-dom';



import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
// import { getColorByPercentage } from "../Common/DashColors"

function samePageLinkNavigation(event) {
  if (
    event.defaultPrevented ||
    event.button !== 0 ||
    event.metaKey ||
    event.ctrlKey ||
    event.altKey ||
    event.shiftKey
  ) {
    return false;
  }
  return true;
}

function LinkTab(props) {
  return (
    <Tab
      component={Link}
      to={props.href}
      {...props}
    />
  );
}

LinkTab.propTypes = {
  selected: PropTypes.bool,
};



const DashboardTitle = (props) => {

  useEffect(() => {
    // fetchUserDetails()
    fetchOptionList()

  }, [])


  const [userName, setUserName] = useState(props.userDetails ? props.userDetails.name.split(' ')[0] : '')


  const [progressBarColors,setProgressBarColors] = useState([])

const fetchOptionList = async () => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("dash_access_token")}`,
      },
    };
    const response = await axios.get("/backend/get_all_options", config);
    let data = response.data;
    // progressBarColors = data.progress_bar_color;
    setProgressBarColors(data.progress_bar_color)
  } catch (error) {
    console.error('There was an error fetching the domain data!', error);
  }
};

  const getColorByPercentage = (percentage) => {
  if (percentage >= 0 && percentage <= 100) {
    const index = Math.floor(percentage / 10) ;
    return progressBarColors[index-1];
  }
  return null;
};


  const useStyles = makeStyles({
    progressBarContainer: {
      position: 'relative',
      width: '100%',
      height: '1.5em',
      backgroundColor: '#e0e0e0',
      borderRadius: '4px',
      cursor: 'pointer'
    },
    progressBar: {
      height: '100%',
      borderRadius: '4px',
      transition: 'width 0.2s ease-in-out',
    },
    progressText: {
      width: '1px',
      height: '1px',
      position: 'absolute',
      top: '2px',
      display: 'flex'
    },
    projectLabel: {
      fontSize: '1.25em',
    },

    fileItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '1em',
      borderBottom: `1px solid black`,
      '&:last-child': {
        borderBottom: 'none',
      },
    },
    fileName: {
      flexGrow: 1,
    },
    downloadIcon: {
      marginLeft: '1em',
      color: 'blue',
    },
    dialogActions: {
      padding: '1em',
    },
  });

  const styles = useStyles();
  const navigate = useNavigate()
  const handlePreviousNavigate = () => {
    navigate(-1)
  }
  const backHoverStyles = {
    color: 'white',
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    // event.type can be equal to focus with selectionFollowsFocus.
    if (
      event.type !== 'click' ||
      (event.type === 'click' && samePageLinkNavigation(event))
    ) {
      setValue(newValue);
    }
  };
  const location = useLocation();


  useEffect(() => {
    // Synchronize the tab state with the current pathname
    switch (location.pathname) {
      case '/dash/dashboard':
        setValue(0);
        break;
      case '/dash/dashboard/sprint':
        setValue(1);
        break;
      case '/dash/dashboard/sprint/sprintView':
        setValue(1);
        break;
      default:
        setValue(0);
        break;
    }
  }, [location.pathname]);


  return (
    <>
      {props.isLoaded &&
        <>
          {props.dashboardActivated ? (
            <div className='d-flex justify-content-between align-items-center' style={{ margin: '1em 2em', borderBottom: '1px solid black',marginBottom:'0' }}>
              <div style={{ fontSize: '1.5em', fontWeight: 'bold', lineHeight: '1em' }}>Welcome {userName}!</div>
              <Box>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="nav tabs example"
                  role="navigation"
                >
                  <LinkTab label="Project Overview" href="/dash/dashboard" />
                  <LinkTab label="Sprint Overview" href="/dash/dashboard/sprint" />
                </Tabs>
              </Box>
            </div>
          ) : props.sprintMode ? (
            <div className='d-flex justify-content-between align-items-center' style={{ margin: '0.5em 2em', borderBottom: '1px solid black' }}>
              <div style={{ fontSize: '1.5em', fontWeight: 'bold', lineHeight: '1em' }}>Welcome {userName} !</div>
              <Box>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="nav tabs example"
                  role="navigation"
                >
                  <LinkTab label="Project Overview" href="/dash/dashboard" />
                  <LinkTab label="Sprint Overview" href="/dash/dashboard/sprint" />
                </Tabs>
              </Box>
            </div>
          ) : props.listView ? (
            <div className='d-flex justify-content-between align-items-center' style={{ margin: '0.25em 2em', borderBottom: '1px solid black' }}>
              <div style={{ fontSize: '1.25em', fontWeight: 'bold', lineHeight: '1em' }} className='d-flex justify-content align-items-center'>
                <div onClick={handlePreviousNavigate} style={{ cursor: 'pointer' }}>
                  <div className={classes.domainBack}>
                    <ArrowCircleLeftIcon style={{ color: 'black' }} />
                  </div>
                </div>
                <div style={{ fontWeight: 'bold', marginLeft: '1em' }}>{props.projectName} Over View ({props.domainName}) </div>
              </div>
              <Box>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="nav tabs example"
                  role="navigation"
                >
                  <LinkTab label="Project Overview" href="/dash/dashboard" />
                  <LinkTab label="Sprint Overview" href="/dash/dashboard/sprint" />
                </Tabs>
              </Box>
            </div>
          ) : props.tableView ? (
            <>
              <div className='d-flex justify-content-between align-items-center' style={{ margin: '0.5em 1em', borderBottom: '1px solid black' }}>
                <div style={{ fontSize: '1.25em', fontWeight: 'bold', lineHeight: '1em', width: '50%' }} className='d-flex justify-content align-items-center'>
                  <div style={{ width: '100%' }} className='d-flex justify-content-between align-items-center'>
                    <div style={{ width: '100%' }}>
                      <strong>Sprint Progress is at {Math.round(props.sprintProgress)}%</strong>
                    </div>
                    <div style={{ padding: '0.5em', width: '100%', borderRadius: '40px' }}>
                      <Box className={styles.progressBarContainer}>
                        <div
                          className={styles.progressBar}
                          style={{
                            width: `${props.sprintProgress}%`,
                            backgroundColor: props.sprintProgress && getColorByPercentage(props.sprintProgress),
                          }}
                        >
                        </div>
                      </Box>
                    </div>
                  </div>
                </div>
                <Box>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="nav tabs example"
                    role="navigation"
                  >
                    <LinkTab label="Project Overview" href="/dash/dashboard" />
                    <LinkTab label="Sprint Overview" href="/dash/dashboard/sprint" />
                  </Tabs>
                </Box>
              </div>


            </>
          ) :
            (
              <div className='d-flex justify-content-between align-items-center' style={{ margin: '1em 2em', borderBottom: '1px solid black' }}>
                <div style={{ fontSize: '1.25em', fontWeight: 'bold', lineHeight: '1em' }} className='d-flex justify-content align-items-center'>
                  <div onClick={handlePreviousNavigate} style={{ cursor: 'pointer' }}>
                    <div className={classes.domainBack}>
                      <ArrowCircleLeftIcon style={{ color: 'black' }} />
                    </div>
                  </div>
                  {/* <div style={{ marginLeft: '1em' }}>Welcome {userName} !</div> */}
                  <div style={{ marginLeft: '1em' }}>Welcome to   {props.domainName} !</div>
                </div>
                <Box>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="nav tabs example"
                    role="navigation"
                  >
                    <LinkTab label="Project Overview" href="/dash/dashboard" />
                    <LinkTab label="Sprint Overview" href="/dash/dashboard/sprint" />
                  </Tabs>
                </Box>
              </div>
            )
          }
        </>
      }
    </>
  );
};

export default DashboardTitle