import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsGantt from "highcharts/modules/gantt";
import {
  getMonthStartAndEnd,
  getDateNDaysBefore,
  getDateNDaysAfter,
  getCurrentWeekStartAndEnd,
  getDateTimeLabelFormats,
  getDaysInMonth,
  getFirstDates,
  getFirstDatesPrev
} from "./dateutils";
import NavigationButtons from "./NavigationButtons";
import Button from '@mui/material/Button';
import { result } from './Datares';
import { transformDataForProject } from './Datares';
import classes from './GantChart.module.css'

import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { ReactComponent as GantView } from '../../Assets/GantView.svg';
import { Box } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { useNavigate, useLocation } from 'react-router-dom';
import { getCookie } from '../../Services/Cookie/Cookie';
import axios from 'axios'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';



HighchartsGantt(Highcharts);

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 40,
  height: 20,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(22px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : 'rgba(0,0,0,1)',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 15,
    height: 15,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 1)' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },

}));




const GanttChart = (props) => {
  const [listView, setListView] = useState(true)
  const [activeView, setActiveView] = useState('gantt');
  const currentDate_new = new Date().toISOString().slice(0, 16);
  const location = useLocation();
  const [title, setTitle] = useState('');
  // const { domainId, projectId, dashboard, dashboardActivated, domainName, userDetails, projectName, project_progress, user_progress, toggleActive } = location.state;
  const { setFullView, fullView, domainId, projectId, dashboard, dashboardActivated, domainName, userDetails, projectName, project_progress, user_progress, toggleActive, setIsLoaded, isLoaded, setListViewActive } = props;

  const [progressPercentage, setProgressPercentage] = useState(0)


  const [taskResult, setTaskResult] = useState([])
  const [taskLoaded, setTaskLoaded] = useState(false)

  const navigate = useNavigate()
  const handleToggle = (view) => {
    setActiveView(view);
    if (view == 'gantt') {
      return
    }
    else {
      let state = {
        task_id: 'TD-01'
      }
      setListViewActive(true)
    }

  };

  const [selectedProject, setSelectedProject] = useState(null);
  const [progressBarColors,setProgressBarColors] = useState([]);


  const fetchOptionList = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("dash_access_token")}`,
        },
      };
      const response = await axios.get("/backend/get_all_options", config)
      let data = response.data
      setProgressBarColors(data.progress_bar_color)
    } catch (error) {
      console.error('There was an error fetching the domain data!', error);
    }
  };





  const projects = [
    { id: 1, name: 'Project A' },
    { id: 2, name: 'Project B' },
    { id: 3, name: 'Project C' },
    { id: 4, name: 'Project D' },
    { id: 5, name: 'Project E' },
  ];

  
  const getMonthName = (mondayDate) => {
    const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
  
    const currentMonth = mondayDate.getUTCMonth(); 

    const sundayDate = new Date(mondayDate);
    sundayDate.setUTCDate(mondayDate.getUTCDate() + 6);
  
    if (sundayDate.getUTCMonth() !== currentMonth) {
      return monthNames[sundayDate.getUTCMonth()];
    }
    return monthNames[currentMonth];
  };
  

  useEffect(() => {
    fetchAllTask()
    // fetchOptionList()
  }, [fullView])

  useEffect(()=>{
    fetchAllTask()
    fetchOptionList()
  },[userDetails])

  const fetchAllTask = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("dash_access_token")}`,
        },
      };

      const is_active = 1;

      const response = await axios.get(`/backend/get_all_tasks_list/${domainId}/${projectId}/${is_active}`, config);
      const taskDetailsList = response.data.tasks;

      const userDetailsList = taskDetailsList.filter(task => {
        // Include the task if task_users_list is not empty and includes userDetails.username
        if (task.task_users_list && task.task_users_list.length > 0 && task.task_users_list.includes(userDetails.username)) {
          return true;
        }
        // Otherwise, do not filter out the task
        return false;
      });


      if (fullView) {

        setProgressPercentage(response.data.projects[0].user_overall_progress)
        transformDataForProject(projectName, userDetailsList)
        let result = transformDataForProject(projectName, userDetailsList)
        setTaskResult(result)
        setIsLoaded(true)
        setTaskLoaded(true)
      }
      else {

        setProgressPercentage(response.data.projects[0].overall_progress)
        transformDataForProject(projectName, taskDetailsList)
        let result = transformDataForProject(projectName, taskDetailsList)
  
        setTaskResult(result)
        setIsLoaded(true)
        setTaskLoaded(true)
      }


    } catch (error) {
      console.error('Error fetching task details:', error);
    }
  };

  // const [fullView, setFullView] = useState(false)
  const handleViewToggle = (event) => {
    setFullView(event.target.checked)
    setTaskLoaded(false)
  }

  const currentDate = new Date();
  const { firstDate, lastDate } = getMonthStartAndEnd(currentDate);
  const firstDateUTC = Date.UTC(
    firstDate.getFullYear(),
    firstDate.getMonth(),
    firstDate.getDate()
  );
  const lastDateUTC = Date.UTC(
    lastDate.getFullYear(),
    lastDate.getMonth(),
    lastDate.getDate()
  );
  const currentDateUTC = Date.UTC(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate()
  );
  const [minDate, setMinDate] = useState(firstDateUTC);
  const [maxDate, setMaxDate] = useState(lastDateUTC);

  const [selectedRange, setSelectedRange] = useState({
    title: "Month",
    range: 30,
  });



  function drawXAxisBackground() {
    var chart = this,
      xAxis = chart.xAxis[0],
      height = 47,
      position = {
        x: chart.plotLeft,
        y: chart.plotTop - height,
        width: xAxis.len,
        height: height
      };

    if (!xAxis.background) {
      xAxis.background = chart.renderer.rect().attr({
        fill: 'rgb(217, 217, 217)',
        zIndex: 0
      }).attr(position).add();
    } else {
      xAxis.background.animate(position);
    }
  }





  function drawYAxisBackground() {
    var chart = this,
      yAxis = chart.yAxis[0],
      totalHeight = yAxis.toPixels(yAxis.max) - yAxis.toPixels(yAxis.min), // Calculate total height of yAxis
      uniqueParents = {}; // Object to store unique parent keys

    // Array to store previously created rectangles
    if (!chart.rectangles) {
      chart.rectangles = [];
    }

    // Remove previously created rectangles
    chart.rectangles.forEach(function (rect) {
      rect.destroy();
    });

    // Clear the rectangles array
    chart.rectangles = [];

    // Access the series data directly from userOptions
    var seriesData = chart.yAxis[0].series[0].userOptions.data;

    // Calculate the width dynamically to fit the container
    var containerWidth = chart.plotLeft + chart.plotWidth;


    seriesData.forEach(function (point) {
        var pointDetails = yAxis.series[0].points.find(p => p.id === point.id);

        if (!pointDetails || !pointDetails.shapeArgs) {
            console.warn('shapeArgs is undefined for point:', point);
            return; // Skip to the next point if shapeArgs is not defined
        }

        var pointHeight = pointDetails.shapeArgs.height;

      // Calculate the position of the rectangle relative to the chart's plot area
      // var rectX = chart.plotLeft; // Start at the left edge of the plot area
      var rectX = 10; // Start at the left edge of the plot area
      var rectY = yAxis.toPixels(point.y) - pointHeight / 2; // Centered vertically on the point
      var rectWidth = chart.plotLeft - 9; // Dynamic width to fit the container
      var rectHeight = pointHeight; // Height of the rectangle matches the point height
      var rectFillColor = point.color || 'transparent';
      var rect;
      if (point.parent) {
        var parentPoint = seriesData.find(p => p.id === point.parent);
            var isExpanded = parentPoint && parentPoint.collapsed !== true;
        if (isExpanded) {
          rect = chart.renderer.rect(rectX, rectY - 9, rectWidth, 50).attr({
            fill: rectFillColor,
            color: 'white',
            zIndex: 0
          }).add();
        } else {
          rect = chart.renderer.rect(rectX, rectY - 9, rectWidth, 50).attr({
            fill: rectFillColor,
            color: 'white',
            zIndex: 0,
            visibility: 'hidden'
          }).add();
        }
      } else {
        rect = chart.renderer.rect(rectX, rectY - 9, rectWidth, 50).attr({
          fill: rectFillColor,
          color: 'white',
          zIndex: 0
        }).add();
      }
      chart.rectangles.push(rect);

    });
  }



  const [chartOptions, setChartOptions] = useState({
    chart: {
      events: {
        render: function () {
          drawXAxisBackground.call(this);
          drawYAxisBackground.call(this);
        }
      }
    },
    global: {
      buttonTheme: {
        fill: "#333333",
        stroke: "#000000",
        style: { color: "#ffffff" },
        states: {
          hover: {
            fill: "#666666",
            style: { color: "#00ffff" },
          },
        },
      },
    },
    yAxis: {
      // labels: {
      //   style: {
      //     color: 'white'
      //   }
      // },

    gridLineWidth: 1,
    },
    xAxis: {
      type: "datetime",
      min: minDate,
      max: maxDate,
      dateTimeLabelFormats: getDateTimeLabelFormats(selectedRange),
      plotLines: [
        {
          value: currentDateUTC,
          color: "grey",
          width: 4,
          dashStyle: 'dot'
        },
      ],
    },
    accessibility: { series: { descriptionFormat: "{name}" } },
    series: [],
    tooltip: {
      borderColor: "black",
      borderRadius: 5,
      borderWidth: 1,
      backgroundColor: "rgba(255, 255, 255, 0.85)", // Light background color
      shadow: true,
      padding: 20, // Padding inside the tooltip
      style: {
        color: "black",
        fontSize: "14px",
        textAlign: "left",
        whiteSpace: "normal",
        display: 'flex',
        flexDirection: 'column',
        height: 'auto', // Adjust height as needed
      },
      formatter: function () {
        return `
            <br/>
            <div style="font-weight: bold;">Name : ${this.point.name}</div>
            <br/>
            <div style="font-weight:bold;">Description : ${this.point.description}</div>
            <br/>
            <div><b>Start:</b> ${Highcharts.dateFormat('%e. %b', this.point.start)}</div>
            <br/>
            <div><b>End:</b> ${Highcharts.dateFormat('%e. %b', this.point.end)}</div>
            <br/>
            <div style="margin-top: 8px;"><b>Assigned to:</b> ${this.point.assigned_to}</div>
          
        `;
      }
    },
    plotOptions: {
      series: {
        pointWidth: 30,
        borderRadius: 10,
        fill: "{point.color}",

        dataLabels: {
          enabled: true,
          format: "{point.name}",
          color: "black",
          padding: 5,
          style: {
            textOutline: "none" // Remove text outline if not needed
          }
        },
      },
    },
    credits: {
      enabled: false
  },
    // title: {
    //   text: `Month of ${getMonthName(new Date())}`,
    //   style: {
    //     color: '#333333',
    //     fontSize: '20px'
    //   }
    // }
  
  });



  const getWeekNumberInMonth = (mondayDate) => {
    const currentMonth = mondayDate.getUTCMonth(); // Get the month of the Monday date
    const currentYear = mondayDate.getUTCFullYear();
  
    // Create a Sunday date (6 days after Monday)
    const sundayDate = new Date(mondayDate);
    sundayDate.setUTCDate(mondayDate.getUTCDate() + 6);
  
    // Check if Sunday is in the next month
    if (sundayDate.getUTCMonth() !== currentMonth) {
      return 1; // Part of the week belongs to the next month, so return 1
    }
  
    // Otherwise, calculate the week number within the current month
    const startOfMonth = new Date(currentYear, currentMonth, 1); // 1st of the month
    const startDay = startOfMonth.getUTCDay(); // Day of the week the month starts on
    const diffDays = mondayDate.getUTCDate() + startDay;
  
    // Return the week number using Math.floor
    return Math.ceil(diffDays / 7);
  };
  

  const updateChartRange = (direction) => {
    const range = selectedRange.range;
    let newMaxDate;
    let newMinDate;
    let newTitle = '';

    if (direction === "prev") {
      if (selectedRange.title === "Month") {
        const day1 = getFirstDatesPrev(chartOptions.xAxis.min);

        newMaxDate = minDate;
        newMinDate = day1;

        const selectedDate = new Date(newMinDate);
        const currentYear = selectedDate.getFullYear();
        newTitle = `Month of ${getMonthName(selectedDate)} (${currentYear})`;
      } else {
        newMinDate = getDateNDaysBefore(minDate, range);
        newMaxDate = getDateNDaysBefore(maxDate, range);

        if (selectedRange.title === "Week") {
          const selectedDate = new Date(newMinDate);
          const { monday } = getCurrentWeekStartAndEnd(selectedDate);
          const currentYear = monday.getFullYear();
          const weekNumber = getWeekNumberInMonth(monday);
          newTitle = `Week ${weekNumber} of ${getMonthName(monday)} (${currentYear})`;
        } else if (selectedRange.title === "Day") {
          const selectedDate = new Date(newMinDate);
          const currentYear = selectedDate.getFullYear();
          newTitle = `Day of ${selectedDate.getUTCDate()} ${getMonthName(selectedDate)} (${currentYear})`;
        }
      }
    } else if (direction === "next") {
      if (selectedRange.title === "Month") {
        const { day1, day2 } = getFirstDates(chartOptions.xAxis.min);

        newMinDate = day1;
        newMaxDate = day2;

        const selectedDate = new Date(newMinDate);
        const currentYear = selectedDate.getFullYear();
        newTitle = `Month of ${getMonthName(selectedDate)} (${currentYear})`;
      } else {
        newMinDate = getDateNDaysAfter(minDate, range);
        newMaxDate = getDateNDaysAfter(maxDate, range);

        if (selectedRange.title === "Week") {
          const selectedDate = new Date(newMinDate);
          const { monday } = getCurrentWeekStartAndEnd(selectedDate);
          const currentYear = monday.getFullYear();
          const weekNumber = getWeekNumberInMonth(monday);
          newTitle = `Week ${weekNumber} of ${getMonthName(monday)} (${currentYear})`;
        } else if (selectedRange.title === "Day") {
          const selectedDate = new Date(newMinDate);
          const currentYear = selectedDate.getFullYear();
          newTitle = `Day of ${selectedDate.getUTCDate()} ${getMonthName(selectedDate)} (${currentYear})`;
        }
      }
    }

    setMinDate(newMinDate);
    setMaxDate(newMaxDate);

    setChartOptions((prevOptions) => ({
      ...prevOptions,
      xAxis: {
        ...prevOptions.xAxis,
        min: newMinDate,
        max: newMaxDate,
      },
      // title: {
      //   text: newTitle
      // }
    }));
    setTitle(newTitle); // Title can be updated separately if it affects other parts of the UI
  };

  useEffect(() => {

    if (taskLoaded) {
      Highcharts.ganttChart("container", chartOptions);
    }
  }, [chartOptions]);



  useEffect(() => {


    if (taskLoaded) {
      setChartOptions(prevOptions => ({
        ...prevOptions,
        series: [taskResult],
      }));
    }
  }, [taskLoaded]);
  const handleRangeChange = (event) => {
    const value = event.target.value;
    let range = getDaysInMonth(1);
    let newMinDate, newMaxDate;
    let newTitle = '';

    if (value === "Week") {
      range = 7;
      let selectedDate = new Date(chartOptions.xAxis.min);
      selectedDate.setUTCHours(0, 0, 0, 0);

      const { monday, sunday } = getCurrentWeekStartAndEnd(selectedDate);

      newMinDate = Date.UTC(
        monday.getUTCFullYear(),
        monday.getUTCMonth(),
        monday.getUTCDate()
      );
      newMaxDate = Date.UTC(
        sunday.getUTCFullYear(),
        sunday.getUTCMonth(),
        sunday.getUTCDate()
      );

      const currentYear = monday.getFullYear();
      const weekNumber = getWeekNumberInMonth(monday);
      newTitle = `Week ${weekNumber} of ${getMonthName(monday)} (${currentYear})`;
  
    } else if (value === "Day") {
      range = 1;
      const temp = new Date(chartOptions.xAxis.min);
      const givenDay = temp.getUTCDay();
      const dayOfWeek = new Date().getUTCDay();

      let daysToAdd = (dayOfWeek + 7 - givenDay) % 7;
      if (daysToAdd === 0) {
        daysToAdd = 7;
      }

      const resultDate = new Date(temp);
      resultDate.setUTCDate(resultDate.getUTCDate() + daysToAdd);

      newMinDate = Date.UTC(
        resultDate.getUTCFullYear(),
        resultDate.getUTCMonth(),
        resultDate.getUTCDate()
      );
      newMaxDate = Date.UTC(
        resultDate.getUTCFullYear(),
        resultDate.getUTCMonth(),
        resultDate.getUTCDate() + 1
      );

      const currentYear = resultDate.getFullYear();
      newTitle = `Day of ${resultDate.getUTCDate()} ${getMonthName(resultDate)} (${currentYear})`;
  
    } else {
      newMinDate = firstDateUTC;
      newMaxDate = lastDateUTC;

      const selectedDate = new Date(chartOptions.xAxis.min);
  
      const currentMinDate = new Date(chartOptions.xAxis.min);
      currentMinDate.setUTCDate(currentMinDate.getUTCDate());
  
      newMinDate = Date.UTC(
        currentMinDate.getUTCFullYear(),
        currentMinDate.getUTCMonth(),
        1
      );
      newMaxDate = Date.UTC(
        currentMinDate.getUTCFullYear(),
        currentMinDate.getUTCMonth() + 1,
        1
      ) - 1;
  
      const currentYear = currentMinDate.getFullYear();
      newTitle = `Month of ${getMonthName(currentMinDate)} (${currentYear})`;
    }

    setSelectedRange({ title: value, range });
    setMinDate(newMinDate);
    setMaxDate(newMaxDate);
    setChartOptions((prevOptions) => ({
      ...prevOptions,
      xAxis: {
        ...prevOptions.xAxis,
        min: newMinDate,
        max: newMaxDate,
        dateTimeLabelFormats: getDateTimeLabelFormats({ title: value }),
      },
      // title: {
      //   text: newTitle
      // }
    }));
  
    setTitle(newTitle);
  };

  const useStyles = makeStyles({
    progressBarContainer: {
      position: 'relative',
      width: '100%',
      height: '1.5em',
      backgroundColor: '#e0e0e0',
      borderRadius: '4px',
      cursor: 'pointer'
    },
    progressBar: {
      height: '100%',
      borderRadius: '4px',
      transition: 'width 0.2s ease-in-out',
    },
    progressText: {
      width: '1px',
      height: '1px',
      position: 'absolute',
      top: '2px',
      display: 'flex'
    },
    projectLabel: {
      fontSize: '1.25em',
    },

    fileItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '1em',
      borderBottom: `1px solid black`,
      '&:last-child': {
        borderBottom: 'none',
      },
    },
    fileName: {
      flexGrow: 1,
    },
    downloadIcon: {
      marginLeft: '1em',
      color: 'blue',
    },
    dialogActions: {
      padding: '1em',
    },
  });
  const styles = useStyles();


  const getColorByPercentage = (percentage) => {
    if (percentage >= 0 && percentage <= 100) {
      const index = Math.floor(percentage / 10);
      return progressBarColors[index];
    }
    return null;
  };

  const [newTask, setNewTask] = useState(false)
  const createTaskModal = () => {
    setNewTask(true)
    setTaskActivated(true)
  }

  const [taskActivated, setTaskActivated] = useState(false)

  return (
    <div className={`${classes['content']}`}>
      <Box sx={{ width: '100%' }} className={classes['tab-container']}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', margin: '0em 1em' }} className='d-flex justify-content-between align-items-center'>
          <div className='d-flex justify-content-between' style={{ width: '100%' }}>
            <div className="d-flex flex-column" style={{ marginLeft: '1em' }}>
              <div style={{ textAlign: 'start', width: '100%' }} >
                <Button
                  onClick={() => handleToggle('list')}
                  sx={{
                    backgroundColor: activeView === 'list' ? 'rgb(172, 169, 231)' : 'white',
                    color: activeView === 'list' ? 'white' : 'black',
                    borderRadius: '20px',
                    '&:hover': {
                      color: activeView === 'list' ? 'white' : 'white',
                      backgroundColor: activeView === 'list' ? 'rgb(120, 117, 179)' : 'rgb(120, 117, 179)',
                    },
                    fontSize: '0.7em',
                    marginRight: '1em'
                  }}
                  variant="contained"
                  startIcon={
                    <FormatListBulletedIcon
                      style={{
                        color: activeView === 'list' ? 'white' : 'black',
                      }}
                    />}
                >
                  ListView
                </Button>
                <Button
                  onClick={() => handleToggle('gantt')}
                  sx={{
                    backgroundColor: activeView === 'gantt' ? 'rgb(172, 169, 231)' : 'white',
                    color: activeView === 'gantt' ? 'white' : 'black',
                    borderRadius: '20px',
                    border: 'none',
                    '&:hover': {
                      color: activeView === 'gantt' ? 'white' : 'white',
                      backgroundColor: activeView === 'gantt' ? 'rgb(120, 117, 179)' : 'rgb(120, 117, 179)',
                    },
                    fontSize: '0.7em',
                    marginRight: '1em'
                  }}
                  variant="contained"
                  startIcon={<GantView />}
                >
                  Gantt Chart View
                </Button>
              </div>
              <div className="d-flex align-items-center justify-content-between" style={{ width: '100%' }}>
                <div className='d-flex justify-content-between align-items-center ' >
                  <div>
                    <span>Full View</span>
                    <FormControlLabel
                      style={{ margin: 0 }}
                      control={
                        <IOSSwitch
                          sx={{ m: 1 }}
                          checked={fullView}
                          onChange={handleViewToggle}
                        />
                      }
                    />
                    <span>My View</span>
                  </div>
                </div>
          
              </div>


            </div>
            {taskLoaded &&
              <>
                <NavigationButtons
                  updateChartRange={updateChartRange}
                  selectedRange={selectedRange}
                  handleRangeChange={handleRangeChange}
                />


              </>
            }

            <div style={{ width: '35%' }} className='d-flex justify-content-between align-items-center'>
              <div style={{ width: '60%' }}><strong>Project Progress is at {progressPercentage} %</strong></div>
              <div style={{ padding: '0.5em', width: '60%', borderRadius: '40px' }}>
                <Box className={styles.progressBarContainer}>
                  <div
                    className={styles.progressBar}
                    style={{
                      width: `${progressPercentage}%`,
                      backgroundColor: getColorByPercentage(progressPercentage),
                    }}
                  >
                  </div>
                </Box>
              </div>
            </div>

          </div>
        </Box>

      </Box>

      {taskLoaded
        &&
        <>
          <div>
            <div
              style={{
                color: '#333333',
                fontSize: '20px',
                marginBottom: '10px',
                fontWeight:'bold'
                // Add other styles as needed
              }}
            >
              {title}
            </div>
          </div>

        <div id="container" style={{ marginTop: "0px", overflow: 'scroll !important' }}></div>
        </>
      }




    </div>
  );
};

export default GanttChart;